import React from 'react';
import InfoElement from './InfoElement';
import {Link} from 'react-router-dom';
import colombia from '../../../assets/icon/co.svg';
import usa from '../../../assets/icon/us.svg';

export default function ContactInfoAlternative() {
  return (
    <div className="mt-5 mt-lg-0">
        <InfoElement
          titulo="Correo Electrónico"
          titleSize={'20px'}
          icono="\e076"
          iconSize={'36px'}>
          <p>
            Para recibir una asesoría escríbanos a:{' '}
            <b>
              {' '}
              <Link to={'mailto:comercial@cosinte.com'}>
                comercial@cosinte.com
              </Link>
            </b>
            <br />
            Servicios de Compliance:{' '}
            <b>
              <Link to={'mailto:cumplimiento.corporativo@cosinte.com'}>
                cumplimiento.corporativo@cosinte.com
              </Link>
            </b>
            <br />
            Notificaciones judiciales:{' '}
            <b>
              <Link to={'mailto:direccion.juridica@cosinte.com'}>
              direccion.juridica@cosinte.com
              </Link>
            </b>
          </p>
        </InfoElement>

        <InfoElement
          titulo="Horario de atención"
          titleSize={'20px'}
          icono="\7d"
          iconSize={'36px'}>
          <p>Lunes a viernes : 7:00 am a 5:00 pm</p>
        </InfoElement>

        <InfoElement
          titulo="¿Dónde nos ubicamos?"
          titleSize={'20px'}
          icono="\e081"
          iconSize={'36px'}>

          <p>
            <img src={colombia} alt="Colombia" />
            Bogotá, Colombia <br />
            <b>Carrera 14 No. 95-61 Chicó</b>
          </p>
        </InfoElement>

        <InfoElement titleSize={'20px'} icono="\e090" iconSize={'36px'}>
          <p>
            <b>
              CO (+57) 601 6052424 <br />
            </b>
            <b>
              CO (+57) 601 7440970 <br />
            </b>
            <b>Operadora:</b> Ext. 100 <br />
            <b>Comercial:</b> Ext. 2000 <br />
          </p>
        </InfoElement>
        <InfoElement
          titulo=""
          titleSize={'20px'}
          icono="\e081"
          iconSize={'36px'}>

          <p>
            <img src={usa} alt="Estados Unidos" />
            Estados Unidos <br />
            <b>9130 South Dadeland Blvd. Suite 1619</b> <br />
            Miami, Florida 33156
          </p>
        </InfoElement>

        <InfoElement titleSize={'20px'} icono="\e090" iconSize={'36px'}>
          <p>
            <b>
              EU (+1) 305 7974441 <br />
            </b>
            <b>
              EU (+1) 305 7979521 <br />
            </b>
          </p>
        </InfoElement>

    </div>
  );
}

        /**
        <InfoElement
          titulo="¿Dónde nos ubicamos?"
          titleSize={'20px'}
          icono="\e081"
          iconSize={'36px'}>

          <p>
            <img src={colombia} alt="Colombia" />
            Bogotá, Colombia <br />
            <b>Carrera 14 No. 95-61 Chicó</b>
          </p>
          <p>
            <img src={usa} alt="Estados Unidos" />
            Estados Unidos <br />
            <b>9130 South Dadeland Blvd. Suite 1619</b> <br />
            Miami, Florida 33156
          </p>
        </InfoElement>

        <InfoElement titleSize={'20px'} icono="\e090" iconSize={'36px'}>
          <p>
            <b>
              CO (+57) 601 6052424 <br />
            </b>
            <b>
              CO (+57) 601 7440970 <br />
            </b>
            <b>
              EU (+1) 305 7974441 <br />
            </b>
            <b>
              EU (+1) 305 7979521 <br />
            </b>
            <b>Operadora:</b> Ext. 100 <br />
            <b>Comercial:</b> Ext. 2000 <br />
          </p>
        </InfoElement> */